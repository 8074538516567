import * as React from "react";
import { useLocation } from "@reach/router";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import ContactSection from "../components/contactSections/contactSection/contactSection";

const ContactPage = () => {
  const location = useLocation();
  console.log(location.state && (location.state as any).source);

  return (
    <Layout>
      <Seo title="Contact" pathname="/contact" />
      <main>
        <ContactSection />
      </main>
    </Layout>
  );
};

export default ContactPage;
