import styled from "@emotion/styled";
import contactOverlay0 from "../../../images/contact_overlay0.svg";
import contactOverlay from "../../../images/company-curve.svg";

const ContactWrapper = styled.section`
  .main-title {
    font-size: 44px;
    line-height: 54px;
    letter-spacing: -0.05em;
  }
  .overlay-one {
    background: url(${contactOverlay0}) center/contain no-repeat;
    width: 749px;
    height: 250px;
    transform: translate(-31%, 50%);
  }
  .overlay-two {
    background: url(${contactOverlay}) center/contain no-repeat;
    width: 1520px;
    height: 507px;
    transform: translate(4%, 0);
  }
`;

export { ContactWrapper };
