import React, { FC, useState } from "react";
import TopText from "../../topText/topText";
import ContactForm from "../../contactForm/contactForm";
import SuccessForm from "../../successForm/successForm";
import BlueSection from "../../blueSetion/blueSection";
import { ContactWrapper } from "./contactSection.styles";

interface Props {
  //   title: string;
}
const ContactSection: FC<Props> = () => {
  const topTextData = {
    title: "Contact us",
    subtitle:
      "Scale your business without worrying about fraud. Sardine helps companies convert more good customers, with less false positives.",
  };
  const [showForm, setShowForm] = useState(true);
  return (
    <ContactWrapper className="section-wrapper font-Inter">
      <div className="layout-width relative md:mb-28">
        <div className="overlay-one absolute top-1/4 left-0 block"></div>
        <div className="overlay-two absolute top-0 righ-0 block"></div>
        <TopText title={topTextData.title} subtitle={topTextData.subtitle} />
        {showForm ? (
          <ContactForm formName={"demo"} setShowForm={setShowForm} />
        ) : (
          <SuccessForm />
        )}
      </div>
    </ContactWrapper>
  );
};

export default ContactSection;
